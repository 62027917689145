<template>
  <div>
    <vs-button
      color="primary"
      class="mt-4 mb-2"
      @click="goBackToVoting"
    >
      <i class="fa fa-undo-alt" /> Voltar para lista
    </vs-button>
    <vs-card class="mt-2 mb-3">
      <div>
        <h3 class="card-title">
          Configurar votação
        </h3>
      </div>

      <!-- Content -->
      <div class="d-flex">
        <!-- Left Form -->
        <section class="w-50">
          <!-- URL -->
          <vs-input
            v-model="votingUrl"
            icon-after="true"
            label-placeholder="icon-after"
            icon="mode_edit"
            label="URL:"
            placeholder="Domínio de acesso"
            class="form-input w-100"
            disabled
            @icon-click="openUrlDialog"
          />
          <br>
          <!-- Voting Name -->
          <vs-input
            v-model="votingName"
            label="Nome da votação:"
            :placeholder="votingNamePlaceholder"
            class="form-input mb-2 w-100"
          />
          <br>
          <div class="d-flex justify-content-between">
            <div class="col-5">
              <!-- Voting Name -->
              <vs-input
                v-model="votingInitDate"
                label="Data de início:"
                placeholder="dd/mm/aaaa"
                class="form-input w-100 mb-2"
              />
            </div>
            <div class="col-5">
              <!-- Voting Name -->
              <vs-input
                v-model="votingInitTime"
                label="Hora de início:"
                placeholder="hh:mm:ss"
                class="form-input w-100 mb-2"
              />
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="col-5">
              <!-- Voting Name -->
              <vs-input
                v-model="votingEndDate"
                label="Data de encerramento:"
                placeholder="dd/mm/aaaa"
                class="form-input w-100 mb-2"
              />
            </div>
            <div class="col-5">
              <!-- Voting Name -->
              <vs-input
                v-model="votingEndTime"
                label="Hora de encerramento:"
                placeholder="hh:mm:ss"
                class="form-input w-100 mb-2"
              />
            </div>
          </div>
        </section>
        <!-- Right side -->
        <section class="d-flex w-50 flex-column justify-content-end ml-2">
          <div class="d-flex justify-content-end">
            <vs-button
              class="submit-invite-btn mr-2"
              type="flat"
              @click="$router.push({ path: '/voting-operations' })"
            >
              Cancelar
            </vs-button>
            <vs-button
              class="submit-invite-btn"
              @click="updateVoting"
            >
              Salvar votação
            </vs-button>
          </div>
        </section>
      </div>
    </vs-card>
    <vs-popup
      class-content="popup-url-dialog"
      title="Seleção do domínio de acesso"
      :active.sync="urlDialog"
    >
      <!-- Base voting project -->
      <div>
        <label
          class="mr-2"
          for="VotingUrlDialog"
        >
          <strong>Domínios disponíveis:</strong>
        </label>
        <br>
        <select
          id="VotingUrlDialog"
          v-model="votingUrl"
          tabindex="0"
          name="VotingUrlDialog"
          class="mb-2 c-select"
        >
          <option
            v-for="item in votingOwnerUrls"
            :key="item.surveyId"
            :value="item.url"
          >
            {{ item.surveyId + ' - ' + item.url }}
          </option>
        </select>
        <vs-button
          class="submit-invite-btn w-25 mt-n2 mb-2 float-right"
          @click="urlDialog = false"
        >
          Ok
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* Services */
import Voting from '@/services/voting';

const voting = new Voting();

export default {
  name: 'ConfigVoting',
  props: {
    votationCode: {
      type: String,
      required: true
    },
    projectId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      voting: {},
      votingUrl: '',
      votingName: '',
      votingNamePlaceholder: 'Votação: cliente x adversário dd/mm/aa',
      votingInitDate: '',
      votingInitTime: '',
      votingEndDate: '',
      votingEndTime: '',

      urlDialog: false,
      votingOwnerUrls: [],
    }
  },
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
  },
  created() {
    this.getVotingProj()
  },
  methods: {
    goBackToVoting() {
      this.$store.dispatch('set_isLoadingActive', false);
      this.$router.push({path: '/voting-operations'});
    },
    getVotingProj() {
      this.$store.dispatch('set_isLoadingActive', true);
      voting.getVotingProj(this.projectId, this.votationCode).then(resp => {
        this.voting = resp
        this.fillUpFields()
        this.$store.dispatch('set_isLoadingActive', false);
      })
    },
    fillUpFields() {
      this.votingUrl = this.voting.url
      this.votingName = this.voting.votationName
      this.votingInitDate = this.voting.votationInitDate
      this.votingInitTime = this.voting.votationInitTime
      this.votingEndDate = this.voting.votationEndDate
      this.votingEndTime = this.voting.votationEndTime
    },
    getSurveyDomains() {
      this.$store.dispatch('set_isLoadingActive', true);
      voting.getSurveyDomains(this.ownerId).then(resp => {
        this.votingOwnerUrls = resp
        this.$store.dispatch('set_isLoadingActive', false);
      })
    },
    async updateVoting() {
      await this.$store.dispatch('set_isLoadingActive', true);
      try {
        const payload = {
          votationCode: this.voting.votationCode,
          url: this.votingUrl,
          votationName: this.votingName,
          initDate: this.votingInitDate,
          initTime: this.votingInitTime,
          endDate: this.votingEndDate,
          endTime: this.votingEndTime,

        }
        await voting.updateVoting(payload)
        await this.$store.dispatch('set_isLoadingActive', false);
        this.$vs.notify({
          color: 'success',
          title: 'Votação atualizada com sucesso!',
          text: '',
        });
        await this.$router.push({ path: '/voting-operations' })
      } catch(err) {
        this.$vs.notify({
          color: 'danger',
          title: 'Erro ao atualizar votação',
          text: err,
        });
      }
    },
    openUrlDialog() {
      this.getSurveyDomains()
      this.urlDialog = true
    }
  }
}
</script>

<style scoped>
.c-select {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #2b2b2b;
  cursor: pointer;
  overflow: hidden;
  padding: 6px;
  text-overflow: ellipsis;
  transition: all .2s ease;
  width: 23.25rem;
}

</style>


