import axios from 'axios';

const baseDomain = process.env.VUE_APP_BASEDOMAIN;
const baseEnv = process.env.VUE_APP_BASEENV;

const baseUrl = `${baseDomain}/${baseEnv}`;

export default class Voting {
  getVotingProjs(payload) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/votationProjects/${payload}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  getVotingMgmtProjs(payload) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/votingMgmt/${payload}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  getVotingProj(projId, votationCode) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/votation/${projId}/${votationCode}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  getSurveyDomains(owner) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/surveyDomains/${owner}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  createVoting(payload) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/votingMgmt/create`;

      axios
        .post(url, payload)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  updateVoting(payload) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/votingMgmt/update`;

      axios
        .put(url, payload)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  initVoting(proj, code) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/votingMgmt/init/${proj}/${code}`;

      axios
        .put(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  endVoting(proj, code) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/votingMgmt/end/${proj}/${code}`;

      axios
        .put(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getVotingStats(payload) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/votationStats/${payload}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo === 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
